import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MessagingSubscriptionRequest } from '../entities-messaging/messaging-subscription-request';
import { MessagingUser } from '../entities-messaging/messaging-user';
import { MessagingSubscriptionTypeRequest } from '../entities-messaging/messaging-subscription-type-request';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var api = 'api';
var version = 'v1';
var ACTION_GET = 'GET';
var ACTION_ADD = 'ADD';
var ACTION_DELETE = 'DELETE';
var user = 'user';
var subscriptions = 'subscriptions';
var subscriptiontype = 'subscriptiontype';
var messagingApiContext = 'messaging-api';
var messagingBaseApiPath = "/" + messagingApiContext + "/" + api + "/" + version;
var messagingApiBasePath = "" + messagingBaseApiPath;
var MessagingService = /** @class */ (function () {
    function MessagingService(http) {
        this.http = http;
    }
    MessagingService.prototype.subscriptionRequest = function (resourceUri, type, action) {
        var subscriptionRequest = new MessagingSubscriptionRequest();
        subscriptionRequest.uri = resourceUri;
        if (type) {
            subscriptionRequest.type = type;
        }
        subscriptionRequest.action = action;
        return this.http.post(messagingApiBasePath + "/" + subscriptions + "/", subscriptionRequest, { observe: 'response' })
            .pipe(map(function (res) { return res.status === 200; }), catchError(function (err) { return of(false); }));
    };
    MessagingService.prototype.getSubscription = function (resourceUri) {
        return this.subscriptionRequest(resourceUri, undefined, ACTION_GET);
    };
    MessagingService.prototype.addSubscription = function (resourceUri, type) {
        return this.subscriptionRequest(resourceUri, type, ACTION_ADD);
    };
    MessagingService.prototype.deleteSubscription = function (resourceUri) {
        return this.subscriptionRequest(resourceUri, undefined, ACTION_DELETE);
    };
    MessagingService.prototype.getMessagingUserData = function () {
        return this.http.get(messagingApiBasePath + "/" + user)
            .pipe(map(function (res) { return new MessagingUser(res); }), catchError(function (err) { return of(undefined); }));
    };
    MessagingService.prototype.setSubscriptionType = function (subscriptionType) {
        var subscriptionTypeRequest = new MessagingSubscriptionTypeRequest();
        subscriptionTypeRequest.subscriptionType = subscriptionType;
        return this.http.post(messagingApiBasePath + "/" + user + "/" + subscriptiontype, subscriptionTypeRequest)
            .pipe(map(function (res) { return new MessagingUser(res); }));
    };
    MessagingService.ngInjectableDef = i0.defineInjectable({ factory: function MessagingService_Factory() { return new MessagingService(i0.inject(i1.HttpClient)); }, token: MessagingService, providedIn: "root" });
    return MessagingService;
}());
export { MessagingService };
