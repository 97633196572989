var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var MessagingResource = /** @class */ (function () {
    function MessagingResource(data) {
        this.uri = data.uri;
        this.application = data.application;
        this.type = data.type;
        this.prefLabel = data.prefLabel || {};
    }
    MessagingResource.prototype.serialize = function () {
        return {
            uri: this.uri,
            application: this.application,
            type: this.type,
            prefLabel: __assign({}, this.prefLabel)
        };
    };
    MessagingResource.prototype.getDisplayName = function (localizer, useUILanguage) {
        if (useUILanguage === void 0) { useUILanguage = false; }
        var displayName = localizer.translate(this.prefLabel, useUILanguage);
        if (displayName && displayName.endsWith(' (und)')) {
            return displayName.substring(0, displayName.length - 6);
        }
        return displayName ? displayName : this.uri;
    };
    MessagingResource.prototype.clone = function () {
        return new MessagingResource(this.serialize());
    };
    return MessagingResource;
}());
export { MessagingResource };
